const slugify = function (text) {
  return encodeURIComponent(text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "")); // Trim - from end of text
};

// Special encoding for problem page
const encodingSlugify = (text) => {
  return encodeURIComponent(text
    .toString()
    .toLowerCase()
    .replace(/(ó)/g, "o")
    .replace(/(è)/g, "e")
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "")); // Trim - from end of text
}

const removeSpaces = (text) => {
  return text ? text.replace(/ /g, "") : '';
};

module.exports = { slugify, removeSpaces, encodingSlugify };
