import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "gatsby";

export const DesktopFooter = ({ staticTexts, notFound }) => {
  return (
    <footer className="footer hide-mobile">
      <div className="footer-main">
        {!notFound ? (
          <LazyLoadImage
            className="footer-logo lazyload"
            src={staticTexts.logoWhite.file.url}
            alt={staticTexts.websiteLogoAltText}
            width={120}
            height={60}
          />
        ) : null}
      </div>

      <p className="footer-secondary">
        {!notFound ? staticTexts.componentFooterText : ""}
      </p>
    </footer>
  );
};

export const MobileFooter = ({ staticTexts, notFound }) => {
  return (
    <footer className="footer hide-desktop">
      <div className="footer-main">
        <Link to={`/`}>
          {!notFound ? (
            <LazyLoadImage
              src={staticTexts.logoWhite.file.url}
              alt={staticTexts.websiteLogoAltText}
              width={120}
              height={60}
            />
          ) : null}
        </Link>
      </div>

      <div className="footer-main"></div>

      <div className="footer-secondary">
        <p>
          {!notFound
            ? staticTexts.componentFooterText.substr(
                0,
                staticTexts.componentFooterText.indexOf("|")
              )
            : ""}
        </p>

        <p>
          {!notFound
            ? staticTexts.componentFooterText.substr(
                staticTexts.componentFooterText.indexOf("|") + 1
              )
            : ""}
        </p>
      </div>
    </footer>
  );
};
